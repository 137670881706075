import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography, Avatar, Button } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import Section from '../Section';
import Collapse from '@material-ui/core/Collapse';

const Reviews = ({ reviews }) => {
  const classes = useStyles();

  return (
    <>
      {reviews.map((r, index) => {
        const text = r.text.split('*');
        return (
          <blockquote className={classes.quote} key={index}>
            {r.source === 'trustpilot' && (
              <img
                src="https://res.cloudinary.com/dakp804eh/image/upload/v1637008899/woxo-landing-page/reviewsSection/trustpilot.png"
                alt="Trustpilot"
                className={classes.quoteImg}
              />
            )}
            {r.source === 'capterra' && (
              <img
                src="https://res.cloudinary.com/dakp804eh/image/upload/v1637008899/woxo-landing-page/reviewsSection/capterra.png"
                alt="capterra"
                className={classes.quoteImg}
              />
            )}
            {r.source === 'appsumo' && (
              <img
                src="https://res.cloudinary.com/dakp804eh/image/upload/v1637008899/woxo-landing-page/reviewsSection/appsumo.png"
                alt="appsumo"
                className={clsx(classes.quoteImg, 'h-25')}
              />
            )}
            {r.source === 'g2' && (
              <img
                src="https://res.cloudinary.com/dakp804eh/image/upload/v1637008913/woxo-landing-page/reviewsSection/g2.png"
                alt="G2"
                className={clsx(classes.quoteImg, 'h-45')}
              />
            )}
            <p>
              <span>“</span>
              {text.map((t, i) => {
                return (
                  <>
                    {i % 2 !== 0 ? (
                      <>
                        <span className={classes.quoteTextClipping}>{t}</span>{' '}
                      </>
                    ) : (
                      <span>{t}</span>
                    )}
                  </>
                );
              })}
              <span>”</span>
            </p>
            <cite className={classes.cite}>
              <a href={r.cite.link} target="_blank" rel="noreferrer">
                {r.cite.avatar.link !== '' ? (
                  <>
                    <Avatar alt={r.cite.name} src={r.cite.avatar.link} className={classes.avatar} />
                  </>
                ) : (
                  <>
                    <Avatar className={clsx(classes.avatar, `${r.cite.avatar.class}`)}>
                      {r.cite.avatar.initialLetter}
                      <div className="avatarFig"></div>
                    </Avatar>
                  </>
                )}
                {r.cite.name}
                {r.cite.role && <span style={{ fontWeight: 500 }}>&nbsp;-&nbsp;{r.cite.role}</span>}
              </a>
            </cite>
          </blockquote>
        );
      })}
    </>
  );
};

const ReviewsSection = ({ type, smallTitle }) => {
  const classes = useStyles();
  const [showMore, setShowMore] = React.useState(false);
  const [reviews, setReviews] = React.useState([]);
  const [more, setMore] = React.useState([]);

  const data = [
    {
      source: 'trustpilot',
      text: "*It's like having your own little marketing team creating dozens of nice videos in a row, relieving the stress of production and giving you time to breathe again and focus on your core business.* ...the team really listen to their Woxians' feedback and needs and execute quickly and efficiently. If you need to produce a lot of social media content and need to regain control of your days, this is definitely a tool that you want to have !",
      cite: {
        link: 'https://www.trustpilot.com/users/5b98e1074de5666d34f14bfe',
        name: 'JAP',
        role: 'Entrepreneur',
        avatar: {
          initialLetter: 'J',
          link: '',
          class: 'bg-3'
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'capterra',
      text: "WOXO opens the doors to so many possibilities. *If you do any creative marketing online, you need this tool. First off, I purchased WOXO originally for the mass video creation, but saw they had widgets. Took a look and gave them a test and WHOA.* Most software's haven't given TikTok any love, guess what...",
      cite: {
        link: 'https://www.capterra.com/p/232571/WOXO/reviews/3040212/',
        name: 'Wilton T.',
        role: 'Creative Director',
        avatar: {
          initialLetter: '',
          link: 'https://cdn0.capterra-static.com/profile-images/linkedin/8190a50c71724624673fc1a2a0247423.jpeg',
          class: ''
        }
      },
      type: ['all', 'video', 'widget']
    },
    {
      source: 'capterra',
      text: 'I am a content creator, for my social profile I like to share video content on Instagram, TikTok, Youtube every day. * For keeping the pace I need to create a good amount of videos every day.* (With WOXO) I found a gallery of premade templates, where *I can easily put my information and in a matter of time I get a ton of small video content for social share.*',
      cite: {
        link: 'https://www.capterra.com/p/232571/WOXO/reviews/3009281/',
        name: 'Khan Md Mohaiuminul Islam S.',
        role: 'Civil Engineer',
        avatar: {
          initialLetter: '',
          link: 'https://cdn0.capterra-static.com/profile-images/linkedin/5dd8eb2a4cac6de1e3c4e425a06a7aa8.jpeg',
          class: ''
        }
      },
      type: ['all', 'video', 'widget']
    },
    {
      source: 'appsumo',
      text: "*All that content is generating engagement, getting new visitors to your site. You need content there too, right?* The widget maker let's you grab any social account, from any public user, push a few buttons to make it beautiful; the widget maker spits out code you just copy and paste on any webpage. *Getting that fresh content on the page makes static-- read: boring-- pages come alive, and without any money spent on content creators.*",
      cite: {
        link: 'https://appsumo.com/products/woxo/#r627327',
        name: 'Pandacat',
        avatar: {
          initialLetter: 'P',
          link: '',
          class: ''
        }
      },
      type: ['all', 'widget', 'video']
    },
    {
      source: 'trustpilot',
      text: 'What do I dislike? Not much. Once I had a concern and the help desk reacted almost immediately. Once more WOXO surpassed my expectations. It is easy to use and * they are always available for a conversation, email or provide a telephone call. I have actually never had an issue where somebody really did not help me the same day.*',
      cite: {
        link: 'https://www.trustpilot.com/reviews/612658a87668357b4e4574fe?fbclid=IwAR0azMFZ_AF95Hdj34dObEfYnm1PaAdrvrNguORzuUoryXalxiN1NvlvXCA',
        name: 'Derick Briers',
        role: 'CEO',
        avatar: {
          initialLetter: '',
          link: 'https://user-images.trustpilot.com/6126589b80922a0012259fc3/73x73.png',
          class: ''
        }
      },
      type: ['all', 'video', 'widget']
    },
    {
      source: 'appsumo',
      text: 'The video creator that I ideally want is one that runs on AI whereby it works with one of my GPT 3 tools and creates a version 1 of the video which I then will work on. *This has been the easiest and most intuitive editor I have come across thus far.*',
      cite: {
        link: 'https://appsumo.com/products/woxo/#r628825',
        name: 'Sumoling',
        avatar: {
          initialLetter: 'S',
          link: '',
          class: 'bg-3'
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'g2',
      text: 'The reason this platform originally caught my eye was the social media widgets that they offer. I was very *pleasantly surprised when I took a closer look to find something even more useful to my agency.* The fact that they can import data from a google sheet or use their own spreadsheet that is part of the program makes it very *easy to create very personalized videos for either your own personal use or for use with clients.*',
      cite: {
        link: 'https://www.g2.com/products/woxo/reviews/woxo-review-4968406',
        name: 'Michael O.',
        role: 'Developer',
        avatar: {
          initialLetter: 'M',
          link: '',
          class: 'bg-2'
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'trustpilot',
      text: "I've been impressed by the speed of the development and support. Also really enjoy the sneak peaks at new features. *The main reason why I bought this tool is bunk generate a large group of similar unique videos via Google Sheets integration.* It's very useful. Also like the release of the API.",
      cite: {
        link: 'https://www.trustpilot.com/reviews/61454ef4215cfcced2692c3f',
        name: 'Alphan Nyugen',
        avatar: {
          initialLetter: '',
          link: 'https://user-images.trustpilot.com/60862da5be701f0019f0f301/73x73.png',
          class: ''
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'g2',
      text: "*Woxo is very easy to use, and you don't need any technical knowledge.* The best thing is integrating Google sheet/Excel, and *you can now create or edit a video by editing the spreadsheet.* Also, Widget generator is a useful feature with which you can generate multiple videos for your website. Beyond all the technical features, the development team and support team is phenomenal. I would be subscribed to their services just for their energy.",
      cite: {
        link: ' https://www.g2.com/products/woxo/reviews/woxo-review-4985247',
        name: 'Balasubramanian Venkatesh',
        role: 'Director',
        avatar: {
          initialLetter: '',
          link: 'https://images.g2crowd.com/uploads/avatar/image/475464/thumb_square_7e5969ddd5fcf49a717d6dfb108e77c7.jpeg',
          class: ''
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'trustpilot',
      text: "Once I've created my videos in bulk, I can use the video widget tool, which enables me to showcase my exported videos on my website. *As a result, my site visitors will spend more time on my site thus decreasing the bounce rate, and they'll also get a better user experience.*",
      cite: {
        link: 'https://www.trustpilot.com/reviews/613946be655f038751f0b0b1',
        name: 'Mohammed',
        role: 'Online Coach',
        avatar: {
          initialLetter: 'M',
          link: '',
          class: ''
        }
      },
      type: ['all', 'widget']
    },
    {
      source: 'trustpilot',
      text: 'Not only can I *generate any amount of videos I like from my spreadsheets.* But I can go one step further and *create videos through API. Sky is the limit...* It is a piece of cake to create a great variation of different spinned or personalized videos.',
      cite: {
        link: 'https://www.trustpilot.com/reviews/61407fdb215cfcced2656b41',
        name: 'Marzianera Fluis',
        avatar: {
          initialLetter: 'M',
          link: '',
          class: 'bg-3'
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'capterra',
      text: 'The *ease which you can create videos by just importing excel file with quotations or any textual content is the one line description of WOXO.* It does this job very well and very efficiently.',
      cite: {
        link: 'https://www.capterra.com/p/232571/WOXO/reviews/3161404/',
        name: 'Capterra reviewer',
        role: 'Computer Software',
        avatar: {
          initialLetter: 'C',
          link: '',
          class: 'bg-2'
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'g2',
      text: 'They also *have a widget area where you can make widgets to show off different social media account you can embed into your landing pages or websites.* There is also a way to have dynamic content added into the text in a video. *Ideal for marketers.*',
      cite: {
        link: 'https://www.g2.com/products/woxo/reviews/woxo-review-4974813',
        name: 'User in Media Production',
        role: 'Small-Business',
        avatar: {
          initialLetter: 'U',
          link: '',
          class: ''
        }
      },
      type: ['all', 'widget']
    },
    {
      source: 'appsumo',
      text: "...that's WOXO. Do yourself a favor and take the time to play with it. It's really fresh and exciting. *I am already getting goosebumps thinking of how I can deploy it for clients.* Here's the quick take: *WOXO is two completely different tools that create a virtuous circle of content-at-scale.*",
      cite: {
        link: 'https://appsumo.com/products/woxo/#r627327',
        name: 'pandacat',
        avatar: {
          initialLetter: 'P',
          link: '',
          class: 'bg-3'
        }
      },
      type: ['all', 'video', 'widget']
    },
    {
      source: 'trustpilot',
      text: 'WOXO let you create bulk videos from a spreadsheet. You can produce content for Instagram, TikTok and YouTube Shorts really fast. Video content is the future, and creating this content is time-consuming. But *WOXO make it possible to create more relevant content in a short time. The support team is really great and helpful. I can absolutely recommend WOXO!*',
      cite: {
        link: 'https://www.trustpilot.com/users/60ff068c7bf4f10012225cb1',
        name: 'Ann Juni',
        avatar: {
          initialLetter: '',
          link: 'https://user-images.trustpilot.com/60ff068c7bf4f10012225cb1/73x73.png',
          class: ''
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'appsumo',
      text: "I really just wanted to point out that they had me in front of a camera sharing my screen with them trying to resolve this issue with me ASAP. *So if you've had issues in the past with vendors who pretty much ignore you after you make the purchase ~ well yeah they don't do that.*",
      cite: {
        link: 'https://appsumo.com/products/woxo/#r626820',
        name: 'Anthony Becerra',
        avatar: {
          initialLetter: '',
          link: 'https://appsumo2-cdn.appsumo.com/media/users/avatars/Copy_of_Untitled_4.png?width=100&height=100&aspect_ratio=1%3A1',
          class: ''
        }
      },
      type: ['all', 'video', 'widget']
    },
    {
      source: 'trustpilot',
      text: 'I purchased the AppSumo deal for WOXO because I wanted to publish video for social media quickly. Cloud video editors are usually expensive and slow. *I bought WOXO because it is lightweight and I can get out my social media videos quickly. I also like that videos can be edited in bulk. The roadmap looks promising.*',
      cite: {
        link: 'https://www.trustpilot.com/reviews/61393cc6215cfcced2608d36',
        name: 'Deborah Hearne',
        avatar: {
          initialLetter: 'D',
          link: '',
          class: ''
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'appsumo',
      text: "Yes, I can make the same videos *using a regular video editor, but that's time consuming. Woxo doesn't do everything other video editors did but I believe it will cut down a LOT on the time* it takes me to do what I did there. You can pick a template and then just add the text you want to appear on each scene super fast. Changing the scenes was as easy as clicking and searching for what I want. This alone will save me a TON of time.",
      cite: {
        link: 'https://appsumo.com/products/woxo/#r627427',
        name: 'erichammer',
        avatar: {
          initialLetter: 'E',
          link: '',
          class: 'bg-2'
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'g2',
      text: '*Consistently producing social media posts,* specifically Instagram stories, reels, and TikTok videos has always *been a challenge due to how much time they take. WOXO has found a way for us to batch this process* by simply using a spreadsheet-based editor and their premade templates! This feature alone is impressive, but *the bonus is that their social media widgets* are very polished and easy to embed into my site.',
      cite: {
        link: 'https://www.g2.com/products/woxo/reviews/woxo-review-4974783',
        name: 'Carol H',
        role: 'Owner',
        avatar: {
          initialLetter: 'C',
          link: '',
          class: 'bg-3'
        }
      },
      type: ['all', 'video', 'widget']
    },
    {
      source: 'capterra',
      text: "The fastest and bulk video creator which you can't find elsewhere! This feature is what I liked most and I am able to cater my social media promo and also to my clients. *I am deeply impressed by the power and simplicity of the software which otherwise would have taken me countless hours to create that many videos.*",
      cite: {
        link: 'https://www.capterra.com/p/232571/WOXO/reviews/3072771/',
        name: 'Fredrick R.',
        role: 'CEO',
        avatar: {
          initialLetter: 'F',
          link: '',
          class: ''
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'g2',
      text: 'This is an excellent product with excellent potential. The ability to mass create professional looking videos is rare and very well worth the price. *The fact that the developer is openly sharing his insights and giving out helpful hints makes this product even more valuable.*',
      cite: {
        link: 'https://www.g2.com/products/woxo/reviews/woxo-review-4965718',
        name: 'Vijay A.',
        role: 'Senior Business Analyst',
        avatar: {
          initialLetter: '',
          link: 'https://images.g2crowd.com/uploads/avatar/image/500357/thumb_square_6049c10035bcb4905fe00e750151a99d.jpeg',
          class: ''
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'capterra',
      text: 'For creating social media content, this is a fantastic video-making platform. *An amazing tool for mass-producing videos for social media networks. All marketers, business owners, and content creators should read this book.*',
      cite: {
        link: 'https://www.capterra.com/p/232571/WOXO/reviews/3039137/',
        name: 'Martin B.',
        role: 'Owner',
        avatar: {
          initialLetter: 'M',
          link: '',
          class: ''
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'g2',
      text: "No other solution is as easy to use as WOXO. *I am a mobile marketing consultant and I need quick, creative content for my clients...and this software delivered.* The best thing about it is that the videos are 100% unique and original. I can't recommend it enough!",
      cite: {
        link: 'https://www.g2.com/products/woxo/reviews/woxo-review-4965353',
        name: 'Agency in Marketing and Advertising',
        role: 'Small-Business',
        avatar: {
          initialLetter: 'A',
          link: '',
          class: 'bg-2'
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'trustpilot',
      text: 'I bought Woxo to use its widgets. Since we build websites for our clients, showing widgets for different social media channels is difficult. Woxo does it beautifully and the widgets are great to look at. *I highly recommend this tool for someone looking to work with SMM content on their website.*',
      cite: {
        link: 'https://www.trustpilot.com/users/6127b619ed3ccd0013e0ee3d',
        name: 'Usman Latif',
        role: 'Digital Marketing',
        avatar: {
          initialLetter: '',
          link: 'https://user-images.trustpilot.com/6127b619ed3ccd0013e0ee3d/73x73.png',
          class: ''
        }
      },
      type: ['all', 'widget']
    },
    {
      source: 'appsumo',
      text: 'I can finally say that I am *no longer stressed about creating good video content for social media.* Woxo does all the work for me! And what I love the most is that *it can create videos in bulk from spreadsheets and allow you to collaborate with your team* on a Google Sheet simultaneously and in real-time.',
      cite: {
        link: 'https://appsumo.com/products/woxo/#r679568',
        name: 'solesky',
        avatar: {
          initialLetter: '',
          link: 'https://appsumo2-cdn.appsumo.com/media/users/avatars/SOLESKY2.png?width=100&height=100&aspect_ratio=1%3A1',
          class: ''
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'trustpilot',
      text: '*WOXO has helped me produce high-quality videos in a fraction of the time it would have taken me to create them traditionally.* In just a few minutes, you can produce a catchy video with dynamic content that can be shared on social media or sent out in an email campaign. WOXO makes video creation a breeze and is perfect for those looking to increase their online marketing game.',
      cite: {
        link: 'https://www.trustpilot.com/users/611bf661e41e41001298fd4c',
        name: 'Patrick Tanner',
        avatar: {
          initialLetter: 'P',
          link: '',
          class: 'bg-3'
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'g2',
      text: 'The creation of short videos is a time-consuming task. WOXO enables quick production of short videos directly by adding links to different assets in a simple google sheet. *The benefits are mainly time-saving and flexibility. The ability to manage the whole process and share it via google sheet* is a real advantage.',
      cite: {
        link: 'https://www.g2.com/products/woxo/reviews/woxo-review-4965989',
        name: 'Rony T.',
        role: 'Managing Director',
        avatar: {
          initialLetter: 'R',
          link: '',
          class: ''
        }
      },
      type: ['all', 'video']
    },
    {
      source: 'trustpilot',
      text: "Mariela, Dariel and the rest of the team are always available to answer questions QUICKLY! Can't say that enough. I never have to wait long to get a helpful reply. *WOXO is great for anyone who has to come up with content for their social media channels. It is a time-saver.* They keep adding features and they do listen to the community.",
      cite: {
        link: 'https://www.trustpilot.com/users/6126569a49af0200129c7be1',
        name: 'Luis Ortiz',
        avatar: {
          initialLetter: 'L',
          link: '',
          class: 'bg-3'
        }
      },
      type: ['all', 'video', 'widget']
    },
    {
      source: 'g2',
      text: '*If you make lot of short videos this tool can be your weapon.* It can make a lot of videos in so less time. Also, *it has widget which lets you embed your social media posts to your website to increase engagement.*',
      cite: {
        link: 'https://www.g2.com/products/woxo/reviews/woxo-review-4969836',
        name: 'Kumar P.',
        role: 'Co-Owner',
        avatar: {
          initialLetter: '',
          link: 'https://images.g2crowd.com/uploads/avatar/image/496330/thumb_square_c64cbd057eb2188c1664bf4fc01fc6aa.jpeg',
          class: ''
        }
      },
      type: ['all', 'video', 'widget']
    },
    {
      source: 'appsumo',
      text: "Game Changer. Yes, *it's the only platform I've seen where you can create multiple videos at the same time, thus making it the fastest video creator in the market today.* They *also provide widgets* if you need them. The idea behind it is genius.",
      cite: {
        link: 'https://appsumo.com/products/woxo/#r668654',
        name: 'Honest reviewer',
        avatar: {
          initialLetter: 'H',
          link: '',
          class: ''
        }
      },
      type: ['all', 'video', 'widget']
    }
  ];

  const handleShowMore = () => {
    setShowMore(prev => !prev);
  };

  React.useEffect(() => {
    let idx = 0;
    let _reviews = [];
    let _more = [];

    data.filter(d => {
      if (d.type.indexOf(type) !== -1) {
        idx += 1;
        if (idx <= 8) _reviews.push(d);
        else _more.push(d);
      }
    });

    setReviews(_reviews);
    setMore(_more);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: 'data' should be included
  }, [type]);

  return (
    <Section className={classes.reviewsContainer}>
      <Container style={{ maxWidth: 750 }}>
        <Typography variant="h2" className={clsx(classes.title, smallTitle ? 'smallTitle' : null)}>
          The community loves WOXO 🥰
        </Typography>
        <Box className={classes.textSlogan}>
          <p>
            We thank Woxians for pushing us to do our very best. THANK YOU for your awesome feedback
            (the good, the bad, and the ugly), because{' '}
            <strong>it couldn’t have been possible without you</strong>.
          </p>
        </Box>
      </Container>
      <Container style={{ maxWidth: 1024, paddingTop: 24 }}>
        <Reviews reviews={reviews} />
        {more.length > 0 && (
          <Box className={classes.more}>
            <Collapse in={showMore}>
              <Reviews reviews={more} />
            </Collapse>
            <Button className={classes.button} onClick={handleShowMore}>
              Show {showMore ? 'less' : 'more'}
            </Button>
          </Box>
        )}
      </Container>
    </Section>
  );
};

ReviewsSection.propTypes = {
  type: PropTypes.string,
  smallTitle: PropTypes.bool
};

ReviewsSection.defaultProps = {
  type: 'all',
  smallTitle: false
};

export default ReviewsSection;
